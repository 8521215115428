<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">政务服务</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">智能选址</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">选地块</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">选楼栋</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">选楼房</el-breadcrumb-item>
      <el-breadcrumb-item>企业概况</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="session">
      <div class="back-title fl-c">
        <img src="../../../assets/img/smartLocation/back_loudong.png" alt="" class="title-img back" @click="back" />
        <span class="back" @click="back()">返回选房</span>
      </div>
      <div class="search-box fl-c">
        <div>
          <label>楼层选择</label>
          <el-select v-model="floor" @change="getData">
            <el-option :value="item" :label="'第' + item + '层'" v-for="(item, index) in floorList" :key="index"></el-option>
          </el-select>
        </div>
        <div class="progress-box fl-box">
          <div class="prog green-prog" :style="{ width: (data.leasedArea / data.totalArea) * 100 + '%' }">
            已租赁<span class="progress-value">{{ data.leasedArea }}</span>
          </div>
          <div class="prog yellow-prog" :style="{ width: (data.reserveArea / data.totalArea) * 100 + '%' }">
            已预留<span class="progress-value">{{ data.reserveArea }}</span>
          </div>
          <div class="prog noLease" :style="{ width: (data.noLeaseArea / data.totalArea) * 100 + '%' }">
            未租赁<span class="progress-value">{{ data.noLeaseArea }}</span>
          </div>
        </div>
        <div class="area">
          办公总面积<span class="area-value">{{ data.totalArea }}</span>
        </div>
      </div>
    </div>
    <div class="session">
      <div class="title">入驻企业概况</div>
      <div class="fl-box">
        <div class="item-box">
          <div class="item-title">入驻企业目录</div>
          <div class="table-box table-no-border">
            <el-table :data="data.floorList" border>
              <el-table-column label="房间号" prop="roomNumber" align="center" width="120"></el-table-column>
              <el-table-column label="企业名称" prop="leaseReserveBusiness" align="center"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="item-box">
          <div class="item-title">产业分布</div>
          <v-chart autoresize :options="pieOption" class="chart-dom" style="height: 300px; width: 100%" />
        </div>
      </div>
    </div>
    <div class="session">
      <div class="title">近五年主营收入/净利润</div>
      <v-chart autoresize :options="lineOption" class="chart-dom" style="height: 320px; width: 100%" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      floor: '1',
      build: 2,
      land: 1,
      table: [],
      data: {
        floorList: [],
      },
      income: '主营收入',
      profit: '净利润',
      incomeList: [],
      industryList: [],
      profitList: [],
      yearList: [],
    };
  },
  components: {},
  methods: {
    getData() {
      let p = {
        land: this.land,
        floor: this.floor,
        build: this.build,
      };
      this.$api.findFloorIndustryAndIncome(p).then((res) => {
        if (res.success) {
          this.income = res.result.income;
          this.profit = res.result.profit;
          this.incomeList = res.result.incomeList;
          this.industryList = res.result.industryList;
          this.profitList = res.result.profitList;
          this.yearList = res.result.yearList;
        }
      });
      this.$api.findFloorLeaseDetailByFloorNO(p).then((res) => {
        if (res.success) {
          this.data = res.result;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapState({
      floorList: (state) => state.floorList,
    }),
    pieOption() {
      return {
        color: ['#255ADA', '#14C53E', '#ED7A2C', '#E22132'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}({d}%)',
        },
        series: [
          {
            name: '产业',
            type: 'pie',
            radius: [85, 115],
            center: ['50%', '50%'],
            data: this.industryList,
            label: {
              padding: [0, -50, 25, -50],
              fontSize: 14,
              color: '#333',
            },
            labelLine: {
              length: 20,
              length2: 110,
              lineStyle: {
                color: '#000',
              },
            },
          },
        ],
      };
    },
    lineOption() {
      return {
        color: ['#255ADA', '#ED7A2C'],
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: 20,
          bottom: 35,
          left: 65,
          right: 280,
          containLabel: true,
        },
        legend: {
          show: true,
          right: 55,
          top: 'middle',
          orient: 'vertical',
          itemGap: 20,
          itemWidth: 80,
          textStyle: {
            color: '#666',
            fontSize: 14,
          },
        },
        xAxis: {
          data: this.yearList,
          axisTick: {
            show: true,
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: '#bfbfbf',
            },
          },
          offset: 15,
          axisLabel: {
            color: '#666',
          },
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            name: this.income,
            type: 'line',
            data: this.incomeList,
            symbol: 'circle',
            symbolSize: 11,
          },
          {
            name: this.profit,
            type: 'line',
            data: this.profitList,
            symbol: 'rect',
            symbolSize: 11,
          },
        ],
      };
    },
  },
  mounted() {},
  created() {
    this.floor = this.floorList[0];
    this.build = this.$route.params.build;
    this.land = this.$route.params.land;
    this.getData();
  },
};
</script>


<style lang='scss' scoped>
.session {
  margin: 16px;
  padding: 0 20px 16px;
  background-color: #fff;
}

.title {
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  color: #333;
  line-height: 59px;
  margin-bottom: 16px;
  position: relative;
  padding-left: 14px;
  font-weight: 600;
  &::after {
    content: '';
    position: absolute;
    top: 19px;
    left: 0;
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
  }
}
.back-title {
  font-size: 16px;
  color: #000000;
  line-height: 52px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.title-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.back {
  cursor: pointer;
}

.item-title {
  font-size: 14px;
  color: #000;
  padding-left: 16px;
  position: relative;
  margin-bottom: 16px;
}

.item-title::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #4393f8;
}

.search-box {
  background: #fafafa;
  border: 1px solid #eeeeee;
  padding: 16px;
  line-height: 40px;
  font-size: 14px;
  color: #666666;
}

.search-box label {
  color: #666666;
}

.search-box .el-select {
  height: 40px;
  width: 184px;
  margin-left: 10px;
  margin-right: 100px;
}

.progress-box {
  margin-right: 12px;
  background: #e9e9e9;
  border-radius: 4px;
  overflow: hidden;
  height: 40px;
  width: calc(100% - 612px);
}

.prog {
  color: rgba(255, 255, 255, 0.7);
  height: 100%;
  text-align: center;
  overflow: hidden;
}
.noLease {
  color: rgba(51, 51, 51, 0.7);
  .progress-value {
    color: #333333;
  }
}
.green-prog {
  background: #53c96f;
}
.yellow-prog {
  background: #ed7a2c;
}

.progress-value {
  color: #ffffff;
  font-weight: 600;
  margin-left: 6px;
}

.area {
  width: 232px;
}

.area-value {
  margin-left: 6px;
  font-weight: 600;
  color: #333333;
}

.item-box {
  width: calc(50% - 45px);
}

.item-box:nth-of-type(1) {
  margin-right: 90px;
}
</style>